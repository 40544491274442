import React from 'react'
import Header from './Header'
import Hero from './Hero'
import About from './About'
import Testimony from './Testimony'
import MissionVision from './MissionVision'
import Clients from './Clients'
import Footer from './Footer'

function App() {
	return (
		<div>
			<Header />
			<div> 
				<div id='navbar' className='flex h-0 w-full overflow-y-hidden bg-black text-gray-200 lg:h-auto lg:py-4 lg:pr-8 hd:py-1'>
					<div className='ml-4 mr-2 mt-3 cursor-pointer md:self-center md:mt-0 text-xl md:text-2xl font-semibold lg:hidden'>&times;</div>
					<div className='flex flex-wrap py-2 md:text-xl lg:text-lg lg:py-0 lg:justify-end lg:w-full'>
						<a className='navbar-elem' href='#about'>Acerca de</a>
						<a className='navbar-elem' href='#clients'>Clientes</a>
						<a className='navbar-elem' href='#testimony'>Testimonios</a>
						<a className='navbar-elem' href='#contact'>Contacto</a>
						<a className='navbar-elem' href='#'>Blog</a>
					</div>
				</div>
				<Hero />
				<About />
				<Testimony />
				<MissionVision />
				<Clients />
			</div>
			<Footer />
		</div>
	)
}

export default App
