import React from 'react'
import mapMarker from '../assets/logo/map-marker-alt-solid.svg'
import phoneIcon from '../assets/logo/phone-solid.svg'
import mailIcon from '../assets/logo/envelope-solid.svg'
import RAlogo from '../assets/logo/raul-armas.svg'
import whatsapp from '../assets/logo/whatsapp-square-brands.svg'
import facebook from '../assets/logo/facebook-square-brands.svg'
import instagram from '../assets/logo/instagram-square-brands.svg'
import linkedin from '../assets/logo/linkedin-brands.svg'

function Footer() {
    return (
        <div>
            <div className="flex flex-row w-full px-3 py-4 bg-yellow-birdhouse md:p-5 lg:p-6 lg:px-12 hd:py-4" id='contact'>
                <div className="flex flex-col w-44 md:w-72">
                    <a href="mailto:info@birdhousecoworking.com" className="flex text-sm mb-3 md:text-lg lg:text-lg lg:mb-6 hd:mb-5">
                        <img className="w-5 h-5 mr-2 md:w-6 md:mr-3 lg:w-5" src={mailIcon} alt="mail" />
                        <span>info@birdhousecoworking.com</span>
                    </a>
                    <a href="https://www.google.com/maps/place/Edificio+la+Previsora+Portoviejo/@-1.0570568,-80.451149,21z/data=!4m5!3m4!1s0x902b8d4c698db739:0x7f368310a14411af!8m2!3d-1.0570241!4d-80.4511136" className="flex mb-3 lg:mb-6 hd:mb-5">
                        <img className="w-5 h-5 mt-2 mr-2 md:w-6 md:mr-3 lg:w-5" src={mapMarker} alt="location" />
                        <div className="flex flex-col text-sm leading-5 md:text-lg lg:text-lg">
                            <span className="font-bold">Edificio La Previsora</span>
                            <span>Portoviejo - Ecuador</span>
                        </div>
                    </a>
                    <a href="tel:+593-996-630-130" className="flex text-sm md:text-lg lg:text-lg">
                        <img className="w-5 h-5 mr-2 md:w-6 md:mr-3 lg:w-5" src={phoneIcon} alt="phone" />
                        <span>+593-968-189-981</span>
                    </a>  
                </div>
                <div className="flex flex-col w-full">
                    <div className='flex justify-end mb-10 smmob:flex-col smmob:self-end smmob:mb-0 md:mb-11 lg:mb-16'>
                        <a href="http://bit.ly/Info_birdhouse">
                            <img className='w-5 mr-2 smmob:mb-1 md:w-6 md:mr-3 lg:w-7 hd:w-6' src={whatsapp} alt="whatsapp" />
                        </a>
                        <a href="https://www.facebook.com/Birdhouse-Coworking-627177627631346/">
                            <img className='w-5 mr-2 smmob:mb-1 md:w-6 md:mr-3 lg:w-7 hd:w-6' src={facebook} alt="facebook" />
                        </a>
                        <a href="https://www.instagram.com/birdhouse_coworking/">
                            <img className='w-5 mr-2 smmob:mb-1 md:w-6 md:mr-3 lg:w-7 hd:w-6' src={instagram} alt="instagram" />
                        </a>
                        <a href="https://www.linkedin.com/company/birdhouse-coworking/about/">
                            <img className='w-5 md:w-6 lg:w-7 hd:w-6' src={linkedin} alt="linkedin" />
                        </a>
                    </div>
                    <div className='flex justify-end w-full smmob:hidden'>
                        <a href="https://raularmas.com" target="__blank" className="flex flex-col">
                            <span className="self-end text-sm leading-5 md:text-lg md:mb-1 lg:text-lg">Página desarrollada por</span>
                            <div className="flex w-full justify-end">
                                <img className="w-3 h-5 mr-2 mb-ra md:w-5 md:mr-3 lg:w-4" src={RAlogo} alt="raul armas" />
                                <span className="block text-base font-bold md:text-2xl lg:text-2xl">RAUL ARMAS</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='smmob:flex justify-end w-full hidden'>
                <a href="https://raularmas.com" target="__blank" className="flex flex-col bg-yellow-birdhouse w-full pb-2">
                    <span className="self-center text-sm leading-5 md:text-lg md:mb-1 lg:text-lg">Página desarrollada por</span>
                    <div className="flex w-full justify-center">
                        <img className="w-3 mr-2 mb-ra md:w-5 md:mr-3 lg:w-4" src={RAlogo} alt="raul armas" />
                        <span className="block text-base font-bold md:text-2xl lg:text-2xl">RAUL ARMAS</span>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Footer
