import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import './index.css'

const openNav = () => {
    document.getElementById('navbar').classList.add('h-auto')
    document.getElementById('navbar').classList.add('md:p-3')    
}
  
const closeNav = () => {
    document.getElementById('navbar').classList.remove('h-auto')
    document.getElementById('navbar').classList.remove('md:p-3')
}

ReactDOM.render(
    <App />,
    document.getElementById('app')
)

document.getElementById('navBtn').addEventListener('click', openNav)
document.getElementById('navbar').addEventListener('click', closeNav)