import React from 'react'

function About() {
    return (
        <div className='section' id='about'>
            <p className='title'>Acerca de</p>
			<p className='text'>Coworking es la práctica en la que profesionales de diferentes sectores, autónomos y empresarios, comparten en sinergia un espacio físico para desarrollar sus ideas y proyectos personales.</p>
		</div>
    )
}

export default About