import React from 'react'

function Testimony() {
    return (
        <div className='section pb-3' id='testimony'>
            <p className='title'>Conoce nuestro testimonio</p>
            <div className='w-full h-48 mb-6 md:h-mdt lg:h-lgt hd:w-hdt hd:m-auto hd:pb-20'>
                <iframe width="100%" height="100%" src="https://youtube.com/embed/_a1xuWOKePk?rel=0" frameborder="0"></iframe>
            </div>
        </div>
    )
}

export default Testimony