import React from 'react'

function Hero() {
    return (
        <div className='relative'>
            <div className='animate-show bg-hero w-full h-96 md:h-mdh lg:h-lgh bg-cover bg-center bg-no-repeat'></div>
            <div className='absolute flex justify-center items-center bg-opacity-25 bg-black top-0 left-0 w-full h-96 md:h-mdh lg:h-lgh'>
                <span className='text-white font-bold text-2xl md:text-4xl'>TU LUGAR.</span>
            </div>
        </div>
    )
}

export default Hero