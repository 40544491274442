import React from 'react'

function MisionVision() {
    return (
        <div className='section pb-6 lg:pb-20 bg-black text-gray-200 hd:py-14'>
            <p className='title'>Misión</p>
            <p className='text mb-4 md:mb-9 hd:mb-20'>
                Crear una comunidad de emprendedores, empresas y profesionales independientes, que fomenten el trabajo colaborativo y el crecimiento personal como miembros activos y participativos del primer ecosistema de innovación y emprendimiento de la provincia, de Manabí hacia Latinoamérica.
            </p>
            <p className='title'>Visión</p>
            <p className='text'>
                Ser el principal centro de negocios de la provincia, con sede en sus principales ciudades, referente en Latinoamérica por aportar a la economía regional en base al crecimiento exponencial evidenciado en el éxito de nuestros clientes.
            </p>
        </div>
    )
}

export default MisionVision