import React from 'react'
import headerLogo from '../assets/logo/birdhouse-coworking.png'

function Header() {
    return (
        <div className='flex justify-between p-2 w-full bg-yellow-birdhouse smmob:md:py-3 md:px-5 lg:py-3 lg:px-12 hd:py-1'>
            <img className='w-44 h-16 md:w-64 md:h-24 lg:w-60' src={headerLogo} alt='birdhouse coworking'/>
            <div id='navBtn' className='text-2xl md:text-4xl cursor-pointer mt-2 self-center lg:hidden'>
                <div>&#9776;</div>
            </div>
        </div>
    )
}

export default Header
