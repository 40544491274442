import React from 'react'
import cacoam from '../assets/clients/cacoam.jpg'
import innova from '../assets/clients/innova.png'
import espam from '../assets/clients/espam.png'
import portoviejo from '../assets/clients/portoviejo.png'
import salesiana from '../assets/clients/salesiana.png'
import senescyt from '../assets/clients/senescyt.jpg'
import usgp from '../assets/clients/usgp.png'
import cce from '../assets/clients/cce.png'

function Clients() {
    return (
        <div className='section py-9 hd:pt-20 hd:pb-20' id='clients'>
            <p className="title">Clientes</p>
            <div className='grid grid-cols-4 gap-3 md:gap-x-24 lg:gap-x-40 hd:gap-x-36 hd:mx-32'>
                <img className='self-center p-3 md:p-4' src={portoviejo} alt="Gobierno autonomo descentralizado de Portoviejo"/>
                <img className='self-center p-3 md:p-4' src={cacoam} alt="Camara de comercio americana"/>
                <img className='self-center p-1' src={cce} alt="Casa de la cultura Ecuatoriana"/>
                <img className='self-center' src={senescyt} alt="SENESCYT"/>
                <img className='self-center' src={usgp} alt="Universidad San Gregorio de Portoviejo"/>
                <img className='self-center' src={innova} alt="Innova"/>
                <img className='self-center' src={espam} alt="ESPAM"/>
                <img className='self-center' src={salesiana} alt="Gobierno autonomo descentralizado de Portoviejo"/>    
            </div>
        </div>
    )
}

export default Clients